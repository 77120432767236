import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

import '@nimbus-ds/styles/dist/index.css';
import '@nimbus-ds/styles/dist/themes/dark.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './lib/i18n/i18n';

// Obtém a referência ao elemento root
const rootElement = document.getElementById('root');

// Inicializa o createRoot (adiciona '!' se estiver usando TypeScript)
const root = ReactDOM.createRoot(rootElement!);

// Renderiza a aplicação
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
);
